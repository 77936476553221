import config from '../config'

async function grafanaLogout() {
    const response = await fetch(`${config.grafanaUrl}/logout`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
    })
    // if (!response.ok) {
    //   throw new Error(response.statusText);
    // }

    return response.json()
}

export { grafanaLogout }
