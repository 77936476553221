import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'

import { editOrg } from '../../hooks/orgEdit.js'
import './style.css'

const OrgEditModal = ({ show, onHide, orgId, orgName, getOrgInfo, updateMessage }) => {
    const [newOrgName, setNewOrgName] = useState(null)

    useEffect(() => {
        if (orgName) {
            setNewOrgName(orgName)
        }
    }, [orgName])

    const handleSubmit = () => {
        editOrg({ org_id: orgId, name: newOrgName })
        .then((response) => {
            // Close the modal
            onHide()
            getOrgInfo()
        })
        .catch((error) => {
            updateMessage('error', `[${error.message}] Failed to edit org!`)
        })
    }

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Org</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Org Name</Form.Label>
                    <Form.Control type="text" value={newOrgName} onChange={(e) => setNewOrgName(e.target.value)} />
                </Form.Group>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Edit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default OrgEditModal
