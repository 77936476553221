import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { verifyEmail } from '../../hooks/resetPassword';
import { useNavigate } from 'react-router-dom';

import { FcApproval, FcCancel } from "react-icons/fc";
import './style.css';

const VerificationModal = ({ show }) => {
    const [verificationStatus, setVerificationStatus] = useState(null);
    const [error, setError] = useState('');
    const [variant, setVariant] = useState('success');
    const [message, setMessage] = useState(null);

    const navigate = useNavigate(); // Use navigate for redirection

    // Extract token from URL path
    const idToken = window.location.pathname;
    const token = idToken.split("/")[2]; // Adjust this based on your URL structure

    const handleVerifyEmail = async () => {

        verifyEmail(token)
        .then((res) => {
            setVerificationStatus('success');
            setVariant('success');
            setMessage('Your email has been successfully verified. Please check your inbox for the password setup link to complete the process.');
            setTimeout(() => {
                localStorage.clear();   // Clear local storage
                // navigate('/'); // Redirect to login page after a delay
            }, 3000); // 3 seconds delay
        })
        .catch((error) => {
            setVerificationStatus('error');
            setError(`[${error.message}] Failed to verify email!`);
            setVariant('danger');
            setMessage(
                `${error.message}`
              );
        })

            // let resp = await verifyEmail(token);
            // console.log('resp', resp);

            // if(resp?.data?.status == true)
            // {

            //     setVerificationStatus('success');
            //     setVariant('success');
            //     setMessage('Your email has been verified successfully. Redirecting to the login page...');
            //     console.log('Your email has been verified successfully. Redirecting to the login page...')
            //     setTimeout(() => {
            //         navigate('/'); // Redirect to login page after a delay
            //     }, 3000); // 3 seconds delay
            // }else{

            //     setVerificationStatus('error');
            //     setError(`[${error.message}] Failed to verify email!`);
            //     setVariant('danger');
            //     setMessage(resp?.data?.message);
            // }

    }

    useEffect(() => {
        if (token) {
            handleVerifyEmail();
        }
    }, []);

    return (
        <div className="verification-page-container" style={{ padding: '20px', maxWidth: '800px', margin: '0 auto', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
            <div className="verification-content" style={{ width: '100%' }}>
                {verificationStatus === 'success' && (
                    <div>
                        <div style={{ color: 'green', fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                            <FcApproval size={200} style={{ marginRight: '10px' }} />
                        </div>
                        <div style={{ color: 'green', fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                            <h3>{message}</h3>
                        </div>
                    </div>
                )}
                {verificationStatus === 'error' && (
                    <div>
                        <div style={{ color: 'red', fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                            <FcCancel size={200} style={{ marginRight: '10px' }} />
                        </div>
                        <div style={{ color: 'red', fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                            <h3>{message}</h3>
                        </div>
                    </div>
                )}
                {!verificationStatus && <h3>Please wait while we verify your email...</h3>}
            </div>
        </div>
    );
};

export default VerificationModal;