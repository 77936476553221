import { BrowserRouter as Router } from 'react-router-dom'
import AppRoutes from './routes'
import AppFooter from './components/footer'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <AppRoutes />
        </Router>
    )
}

export default App
