import React, { useState, useEffect } from "react";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import AppNavBar from "../../components/navbar";
import AppFooter from "../../components/footer";

import UserAddModal from "../../components/userAdd";
import UserEditModal from "../../components/userEdit";
import UserDeleteModal from "../../components/userDelete";

import TeamAddModal from "../../components/teamAdd";
import TeamEditModal from "../../components/teamEdit";
import TeamDeleteModal from "../../components/teamDelete";

import OrgEditModal from "../../components/orgEdit";
import OrgDeleteModal from "../../components/orgDelete";
import MessagePopup from "../../components/messagePopup";

import { getRawOrgInfo } from "../../hooks/orgInfo";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import config from "../../config.js";

import "./style.css";
import { MdVerified } from "react-icons/md";

export const Home = ({ token, setToken }) => {
  const [userInfo, setUserInfo] = useState("");
  const [orgsInfo, setOrgsInfo] = useState([]);
  const [showOrgEditModal, setShowOrgEditModal] = useState(false);
  // message window content
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  // handle user add modal
  const [orgIdToModify, setOrgIdToModify] = useState(null);
  const [showUserAddModal, setShowUserAddModal] = useState(false);
  // handle user edit/delete modal
  const [userIdToModify, setUserIdToModify] = useState(null);
  const [userNameToModify, setUserNameToModify] = useState(null);
  const [showUserEditModal, setShowUserEditModal] = useState(false);
  const [showUserDeleteModal, setShowUserDeleteModal] = useState(false);
  // handle team add modal
  const [showTeamAddModal, setShowTeamAddModal] = useState(false);
  // handle team edit/delete modal
  const [teamIdToModify, setTeamIdToModify] = useState(null);
  const [teamNameToModify, setTeamNameToModify] = useState(null);
  const [timezoneToModify, setTimezoneToModify] = useState(null);

  const [showTeamEditModal, setShowTeamEditModal] = useState(false);
  const [showTeamDeleteModal, setShowTeamDeleteModal] = useState(false);
  // the org id of the current page
  const [orgId, setOrgId] = useState("");
  const [orgName, setOrgName] = useState("");
  const [showOrgDeleteModal, setShowOrgDeleteModal] = useState(false);
  // change the layout by page width
  const [isWideEnough, setIsWideEnough] = useState(window.innerWidth > 1000);
  const [haveGotOrgInfo, setHaveGotOrgInfo] = useState(false);

  const getOrgInfo = () => {
    getRawOrgInfo()
      .then((response) => {
        setOrgsInfo(response.data);
        let tempOrgData = response.data?.find(
          (item) => item.orgId == localStorage.getItem("orgId")
        );

        if (!orgId) {
          setOrgId(tempOrgData?.orgId || response.data[0].orgId);
        }
        // set the org name
        if (!orgName) {
          setOrgName(tempOrgData?.orgName || response.data[0].orgName);
        }

        setHaveGotOrgInfo(true);
      })
      .catch((error) => {
        updateMessage("error", "Failed to get the org info!");
      });
  };

  useEffect(() => {
    getOrgInfo();
  }, [token, showOrgEditModal]);

  useEffect(() => {
    setUserInfo({
      token: localStorage.getItem("token"),
      username: localStorage.getItem("username"),
      userId: localStorage.getItem("userId"),
      role: localStorage.getItem("role"),
    });
    // handle the change of user
    getOrgInfo();
  }, [token]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.href = "/signin";
    }
  }, []);

  const updateMessage = (type, content) => {
    setMessageType(type);
    setMessage(content);
  };

  const handleGrafana = (grafanaId) => {
    window.location.href = `${config.apiUrl}/grafana_oidc/${grafanaId}`;
  };

  const handleUserAddModal = (status, orgId) => {
    if (status) {
      // pass the org id to modal
      setOrgIdToModify(orgId);
    }
    setShowUserAddModal(status);
  };

  const handleUserEditModal = (status, userId, orgId) => {
    if (status) {
      // pass the id and org id to modal
      setUserIdToModify(userId);
      setOrgIdToModify(orgId);
      setShowUserEditModal(status);
    } else {
      setUserIdToModify(null);
      setOrgIdToModify(null);
      setShowUserEditModal(status);
    }
  };

  const handleUserDeleteModal = (status, userId, username) => {
    if (status) {
      // pass the id and username to modal
      setUserIdToModify(userId);
      setUserNameToModify(username);
      setShowUserDeleteModal(status);
    } else {
      setShowUserDeleteModal(status);
    }
  };

  const handleFormRedirect = (formId) => {
    window.location.href = `/form/${formId}`;
  };

  const handleAnomalyListRedirect = (teamId) => {
    window.location.href = `/anomalylist/${teamId}`;
  };

  const handleTeamAddModal = (status, orgId) => {
    if (status) {
      // pass the org id to modal
      setOrgIdToModify(orgId);
    }
    setShowTeamAddModal(status);
  };

  const handleTeamEditModal = (status, teamName, teamId, timezone) => {
    if (status) {
      // pass the name and id to modal
      setTeamIdToModify(teamId);
      setTeamNameToModify(teamName);
      setTimezoneToModify(timezone);
      setShowTeamEditModal(status);
    } else {
      setTeamIdToModify(null);
      setTeamNameToModify(null);
      setShowTeamEditModal(status);
    }
  };

  const handleTeamDeleteModal = (status, teamName, teamId) => {
    if (status) {
      // pass the name and id to modal
      setTeamIdToModify(teamId);
      setTeamNameToModify(teamName);
      setShowTeamDeleteModal(status);
    } else {
      setTeamIdToModify(null);
      setTeamNameToModify(null);
      setShowTeamDeleteModal(status);
    }
  };

  const handleTabSelect = (eventKey, event) => {
    setOrgId(eventKey);
    localStorage.setItem("orgId", eventKey);
    // set the org name
    setOrgName(event.target.innerText);
  };

  const handleOrgEditModal = (status) => {
    setShowOrgEditModal(status);
  };

  const handleOrgDeleteModal = (status) => {
    setShowOrgDeleteModal(status);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsWideEnough(window.innerWidth > 1000);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return haveGotOrgInfo ? (
    isWideEnough ? (
      <div className="homepage">
        <AppNavBar getOrgInfo={getOrgInfo} />
        <div className="tabs-container">
          <Tabs
            defaultActiveKey={
              localStorage.getItem("orgId")
                ? localStorage.getItem("orgId")
                : orgsInfo[0].orgId
            }
            className="mb-3"
            justify
            onSelect={handleTabSelect}
          >
            {orgsInfo &&
              orgsInfo.map((org, index) => (
                <Tab eventKey={org.orgId} title={org.orgName}>
                  {/* Welcome Title */}
                  <div className="card-container">
                    <div className="org-title-container">
                      <h3 className="org-h3">
                        Welcome to{" "}
                        <span className="orgname-text">{org.orgName}</span>,{" "}
                        {userInfo.username}!
                      </h3>
                      <div className="org-btn-container">
                        {userInfo.role === "Ensaras Admin" && (
                          <Button
                            variant="light"
                            className="org-btn text"
                            onClick={() => {
                              handleOrgEditModal(true);
                            }}
                          >
                            Edit Org
                          </Button>
                        )}

                        {false && userInfo.role === "Ensaras Admin" && (
                          <Button
                            variant="light"
                            className="org-btn text"
                            onClick={() => {
                              handleOrgDeleteModal(true);
                            }}
                          >
                            Delete Org
                          </Button>
                        )}
                      </div>
                    </div>

                    {/* Account Management */}
                    {(userInfo.role === "Ensaras Admin" ||
                      userInfo.role === "Client Admin") && (
                      <Card className="section">
                        <Card.Body>
                          <Card.Title className="card-title">
                            Account Management  
                          </Card.Title>
                          <div className="list-container">
                            {org.users.map((user) => (
                              <Card className="list-item flex-grow-1">
                                <span className="verification-status">
                                  {user.is_verified && (
                                    <MdVerified color="#16a34a" />
                                  )}
                                </span>
                                <div className="account-sect">
                                  {user.username}
                                  <div className="account-sect-child">
                                    <button
                                      onClick={() => {
                                        handleUserEditModal(
                                          true,
                                          user.userId,
                                          org.orgId
                                        );
                                      }}
                                      className="text account-ops"
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={() => {
                                        handleUserDeleteModal(
                                          true,
                                          user.userId,
                                          user.username
                                        );
                                      }}
                                      className="text account-ops"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </Card>
                            ))}
                            <Card className="list-item flex-grow-1">
                              <div className="account-sect">
                                <button
                                  onClick={() => {
                                    handleUserAddModal(true, org.orgId);
                                  }}
                                  className="text account-ops"
                                >
                                  Add User
                                </button>
                              </div>
                            </Card>
                          </div>
                        </Card.Body>
                      </Card>
                    )}

                    {userInfo.role ===
                      "Ensaras Admin" /* || userInfo.role === 'Client Admin' */ && (
                      <Button
                        variant="light"
                        className="ctl-btn text team-add-btn"
                        onClick={() => {
                          handleTeamAddModal(true, org.orgId);
                        }}
                      >
                        Add Team
                      </Button>
                    )}

                    {org.teams.map((team, index) => (
                      <Card className="section">
                        <Card.Body>
                          <div className="team-sect team-title">
                            <Card.Title className="card-title">
                              {team.teamName}
                            </Card.Title>

                            <div className="team-btn-ctn">
                              {userInfo.role === "Ensaras Admin" &&
                                ![
                                  "Engineer",
                                  "Project Analytics",
                                  "Master Data Management",
                                  "Tech Stack",
                                ].includes(team?.teamName?.trim()) && (
                                  <Button
                                    variant="light"
                                    className="ctl-btn text"
                                    onClick={() => {
                                      handleTeamEditModal(
                                        true,
                                        team.teamName,
                                        team.teamId,
                                        team.timezone
                                      );
                                    }}
                                  >
                                    Edit Team
                                  </Button>
                                )}

                              {userInfo.role ===
                                "Ensaras Admin" /* || userInfo.role === 'Client Admin' */ &&
                                ![
                                  "Engineer",
                                  "Project Analytics",
                                  "Master Data Management",
                                  "Tech Stack",
                                ].includes(team?.teamName?.trim()) && (
                                  <Button
                                    variant="light"
                                    className="ctl-btn text"
                                    onClick={() => {
                                      handleTeamDeleteModal(
                                        true,
                                        team.teamName,
                                        team.teamId
                                      );
                                    }}
                                  >
                                    Delete Team
                                  </Button>
                                )}
                            </div>
                          </div>

                          <div className="team-sect">
                            <Button
                              variant="primary"
                              size="sm"
                              className="dashboard-btn"
                              onClick={() => handleGrafana(org.grafanaId)}
                            >
                              Dashboard
                            </Button>
                            {team.anomalyListId && (
                              <Button
                                variant="primary"
                                size="sm"
                                className="anomaly-btn"
                                onClick={() =>
                                  handleAnomalyListRedirect(team.teamId)
                                }
                              >
                                Anomaly List
                              </Button>
                            )}
                          </div>

                          <div className="team-sect">
                            <h4 className="team-sect-btn">Submit Forms</h4>
                            <div className="list-container">
                              {team.forms.map((form, index) => (
                                <Button
                                  variant="light"
                                  onClick={() =>
                                    handleFormRedirect(form.formId)
                                  }
                                  className="list-item flex-grow-1"
                                >
                                  {form.formName}
                                </Button>
                              ))}
                            </div>
                          </div>

                          {(userInfo.role === "Ensaras Admin" ||
                            userInfo.role === "Client Admin") && (
                            <div className="team-sect">
                              <h4 className="team-sect-btn">Team Members</h4>
                              <div className="list-container">
                                {team.users.map((username) => (
                                  <Card className="list-item flex-grow-1">
                                    {username}
                                  </Card>
                                ))}
                              </div>
                            </div>
                          )}
                        </Card.Body>
                      </Card>
                    ))}
                  </div>
                </Tab>
              ))}
          </Tabs>
        </div>

        <UserAddModal
          show={showUserAddModal}
          onHide={() => handleUserAddModal(false, null)}
          orgId={orgIdToModify}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <UserEditModal
          show={showUserEditModal}
          onHide={() => handleUserEditModal(false, null, null)}
          userId={userIdToModify}
          orgId={orgIdToModify}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <UserDeleteModal
          show={showUserDeleteModal}
          onHide={() => handleUserDeleteModal(false, null, null)}
          username={userNameToModify}
          userId={userIdToModify}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <TeamAddModal
          show={showTeamAddModal}
          onHide={() => handleTeamAddModal(false, null)}
          orgId={orgIdToModify}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <TeamEditModal
          show={showTeamEditModal}
          onHide={() => handleTeamEditModal(false, null, null, null)}
          teamName={teamNameToModify}
          teamId={teamIdToModify}
          timezone={timezoneToModify}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <TeamDeleteModal
          show={showTeamDeleteModal}
          onHide={() => handleTeamDeleteModal(false, null, null)}
          teamName={teamNameToModify}
          teamId={teamIdToModify}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <OrgEditModal
          show={showOrgEditModal}
          onHide={() => handleOrgEditModal(false)}
          orgId={orgId}
          orgName={orgName}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <OrgDeleteModal
          show={showOrgDeleteModal}
          onHide={() => handleOrgDeleteModal(false)}
          orgId={orgId}
          orgName={orgName}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <MessagePopup
          message={message}
          messageType={messageType}
          onClose={() => setMessage("")}
        />

        <AppFooter />
      </div>
    ) : (
      <div className="homepage">
        <AppNavBar getOrgInfo={getOrgInfo} />
        <div className="tabs-container">
          <Tabs
            className="mb-3"
            defaultActiveKey={
              localStorage.getItem("orgId")
                ? localStorage.getItem("orgId")
                : orgsInfo[0].orgId
            }
            justify
            onSelect={handleTabSelect}
          >
            {orgsInfo &&
              orgsInfo.map((org, index) => (
                <Tab eventKey={org.orgId} title={org.orgName}>
                  {/* Welcome Title */}
                  <div className="card-container">
                    <h3 className="org-h3">
                      Welcome to{" "}
                      <span className="orgname-text">{org.orgName}</span>,{" "}
                      {userInfo.username}!
                    </h3>
                    <div className="org-btn-container">
                      {userInfo.role === "Ensaras Admin" && (
                        <Button
                          variant="light"
                          className="org-btn text"
                          onClick={() => {
                            handleOrgEditModal(true);
                          }}
                        >
                          Edit Org
                        </Button>
                      )}

                      {false && userInfo.role === "Ensaras Admin" && (
                        <Button
                          variant="light"
                          className="org-btn text"
                          onClick={() => {
                            handleOrgDeleteModal(true);
                          }}
                        >
                          Delete Org
                        </Button>
                      )}
                    </div>

                    {/* Account Management */}
                    {(userInfo.role === "Ensaras Admin" ||
                      userInfo.role === "Client Admin") && (
                      <Card className="section">
                        <Card.Body>
                          <Card.Title className="card-title">
                            Account Management
                          </Card.Title>
                          <div className="list-container">
                            {org.users.map((user) => (
                              <Card className="list-item flex-grow-1">
                                <span className="verification-status">
                                  {user.is_verified && (
                                    <MdVerified color="#16a34a" />
                                  )}
                                </span>
                                <div className="account-sect">
                                  <span className="account-sect-username">
                                    {user.username}
                                  </span>
                                  <div className="account-sect-child">
                                    <button
                                      onClick={() => {
                                        handleUserEditModal(
                                          true,
                                          user.userId,
                                          org.orgId
                                        );
                                      }}
                                      className="text account-ops"
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={() => {
                                        handleUserDeleteModal(
                                          true,
                                          user.userId,
                                          user.username
                                        );
                                      }}
                                      className="text account-ops"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </Card>
                            ))}
                            <Card className="list-item flex-grow-1">
                              <div className="account-sect">
                                <button
                                  onClick={() => {
                                    handleUserAddModal(true, org.orgId);
                                  }}
                                  className="text account-ops"
                                >
                                  Add User
                                </button>
                              </div>
                            </Card>
                          </div>
                        </Card.Body>
                      </Card>
                    )}

                    {userInfo.role ===
                      "Ensaras Admin" /* || userInfo.role === 'Client Admin' */ && (
                      <Button
                        variant="light"
                        className="ctl-btn text team-add-btn"
                        onClick={() => {
                          handleTeamAddModal(true, org.orgId);
                        }}
                      >
                        Add Team
                      </Button>
                    )}

                    {org.teams.map((team, index) => (
                      <Card className="section">
                        <Card.Body>
                          <Card.Title className="card-title">
                            {team.teamName}
                          </Card.Title>

                          <div className="team-btn-ctn">
                            {userInfo.role ===
                              "Ensaras Admin" /* || userInfo.role === 'Client Admin' */ &&
                              ![
                                "Engineer",
                                "Project Analytics",
                                "Master Data Management",
                                "Tech Stack",
                              ].includes(team?.teamName?.trim()) && (
                                <Button
                                  variant="light"
                                  className="ctl-btn text"
                                  onClick={() => {
                                    handleTeamEditModal(
                                      true,
                                      team.teamName,
                                      team.teamId,
                                      team.timezone
                                    );
                                  }}
                                >
                                  Edit Team
                                </Button>
                              )}

                            {userInfo.role ===
                              "Ensaras Admin" /* || userInfo.role === 'Client Admin' */ &&
                              ![
                                "Engineer",
                                "Project Analytics",
                                "Master Data Management",
                                "Tech Stack",
                              ].includes(team?.teamName?.trim()) && (
                                <Button
                                  variant="light"
                                  className="ctl-btn text"
                                  onClick={() => {
                                    handleTeamDeleteModal(
                                      true,
                                      team.teamName,
                                      team.teamId
                                    );
                                  }}
                                >
                                  Delete Team
                                </Button>
                              )}
                          </div>

                          <div className="team-sect">
                            <Button
                              variant="primary"
                              size="sm"
                              className="dashboard-btn"
                              onClick={() => handleGrafana(org.grafanaId)}
                            >
                              Dashboard
                            </Button>
                            {team.anomalyListId && (
                              <Button
                                variant="primary"
                                size="sm"
                                className="anomaly-btn"
                                onClick={() =>
                                  handleAnomalyListRedirect(team.teamId)
                                }
                              >
                                Anomaly List
                              </Button>
                            )}
                          </div>

                          <div className="team-sect">
                            <h4 className="team-sect-btn">Submit Forms</h4>
                            <div className="list-container">
                              {team.forms.map((form, index) => (
                                <Button
                                  variant="light"
                                  onClick={() =>
                                    handleFormRedirect(form.formId)
                                  }
                                  className="list-item flex-grow-1"
                                >
                                  {form.formName}
                                </Button>
                              ))}
                            </div>
                          </div>

                          {(userInfo.role === "Ensaras Admin" ||
                            userInfo.role === "Client Admin") && (
                            <div className="team-sect">
                              <h4 className="team-sect-btn">Team Members</h4>
                              <div className="list-container">
                                {team.users.map((username) => (
                                  <Card className="list-item flex-grow-1">
                                    <span className="account-sect-username">
                                      {username}
                                    </span>
                                  </Card>
                                ))}
                              </div>
                            </div>
                          )}
                        </Card.Body>
                      </Card>
                    ))}
                  </div>
                </Tab>
              ))}
          </Tabs>
        </div>

        <UserAddModal
          show={showUserAddModal}
          onHide={() => handleUserAddModal(false, null)}
          orgId={orgIdToModify}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <UserEditModal
          show={showUserEditModal}
          onHide={() => handleUserEditModal(false, null, null)}
          userId={userIdToModify}
          orgId={orgIdToModify}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <UserDeleteModal
          show={showUserDeleteModal}
          onHide={() => handleUserDeleteModal(false, null, null)}
          username={userNameToModify}
          userId={userIdToModify}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <TeamAddModal
          show={showTeamAddModal}
          onHide={() => handleTeamAddModal(false, null)}
          orgId={orgIdToModify}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <TeamEditModal
          show={showTeamEditModal}
          onHide={() => handleTeamEditModal(false, null, null)}
          teamName={teamNameToModify}
          teamId={teamIdToModify}
          timezone={timezoneToModify}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <TeamDeleteModal
          show={showTeamDeleteModal}
          onHide={() => handleTeamDeleteModal(false, null, null)}
          teamName={teamNameToModify}
          teamId={teamIdToModify}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <OrgEditModal
          show={showOrgEditModal}
          onHide={() => handleOrgEditModal(false)}
          orgId={orgId}
          orgName={orgName}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />

        <OrgDeleteModal
          show={showOrgDeleteModal}
          onHide={() => handleOrgDeleteModal(false)}
          orgId={orgId}
          orgName={orgName}
          getOrgInfo={getOrgInfo}
        />

        <MessagePopup
          message={message}
          messageType={messageType}
          onClose={() => setMessage("")}
        />

        <AppFooter />
      </div>
    )
  ) : (
    <div>Loading...</div>
  );
};
