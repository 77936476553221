import React from 'react'
import { Modal, Button } from 'react-bootstrap'

import { deleteOrg } from '../../hooks/orgDelete.js'
import './style.css'

const OrgDeleteModal = ({ show, onHide, orgId, orgName, getOrgInfo, updateMessage }) => {
    const handleSubmit = () => {
        deleteOrg({ org_id: orgId })
        .then((response) => {
            // Close the modal
            onHide()
            getOrgInfo()
        })
        .catch((error) => {
            updateMessage('error', `[${error.message}] Failed to delete org!`)
        })
    }

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Delete Org</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>Are you sure you want to delete the org "{orgName}"?</p>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default OrgDeleteModal
