import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'

import { editAnomaly } from '../../hooks/anomalyEdit.js'
import './style.css'

const AnomalyEditModal = ({ show, onHide, dataInfo, teamId, template, getFormInfo, updateMessage }) => {
    const [data, setData] = useState(null)

    useEffect(() => {
        // use copy of info
        console.log(dataInfo)
        console.log(template)
        setData({ ...dataInfo })
    }, [dataInfo])

    const handleSubmit = () => {
        editAnomaly({classification: data.classification, explanation: data.explanation}, dataInfo.id)
        .then((response) => {
            // Close the modal
            onHide()
            getFormInfo()
        })
        .catch((error) => {
            updateMessage('error', `[${error.message}] Failed to edit anomaly!`)
        })
    }

    const handleUpdate = (e, field) => {
        setData((prevValues) => {
            return { ...prevValues, [field]: e.target.value }
        })
    }

    // render each field by type
    const renderFields = (field) => {
        if (data) {
            switch (field.type) {
                case 'datetime':
                    return (
                        <Form.Group key={field.var} controlId={field.var}>
                            <Form.Label>{field.name}</Form.Label>
                            <Form.Control type="text" value={data[field.var]} onChange={(e) => handleUpdate(e, field.var)} disabled={true}/>
                        </Form.Group>
                    )

                case 'decimal':
                    return (
                        <Form.Group key={field.var} controlId={field.var}>
                            <Form.Label>{field.name}</Form.Label>
                            <Form.Control type="number" step="0.01" value={data[field.var]} onChange={(e) => handleUpdate(e, field.var)} disabled={true}/>
                        </Form.Group>
                    )

                case 'date':
                    return (
                        <Form.Group key={field.var} controlId={field.var}>
                            <Form.Label>{field.name}</Form.Label>
                            <Form.Control type="date" value={data[field.var]} onChange={(e) => handleUpdate(e, field.var)} disabled={true}/>
                        </Form.Group>
                    )

                case 'choice':
                    if (field.var === 'classification') {
                        return (
                            <Form.Group key={field.var} controlId={field.var}>
                                <Form.Label>{field.name}</Form.Label>
                                <Form.Control as="select" value={data[field.var]} onChange={(e) => handleUpdate(e, field.var)}>
                                    {field.choices.map((choice, index) => (
                                        <option key={index} value={choice}>
                                            {choice}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        )
                    } else {
                        return (
                            <Form.Group key={field.var} controlId={field.var}>
                                <Form.Label>{field.name}</Form.Label>
                                <Form.Control as="select" value={data[field.var]} onChange={(e) => handleUpdate(e, field.var)}  disabled={true}>
                                    {field.choices.map((choice, index) => (
                                        <option key={index} value={choice}>
                                            {choice}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        )
                    }

                case 'text':
                    return (
                        <Form.Group key={field.var} controlId={field.var}>
                            <Form.Label>{field.name}</Form.Label>
                            <Form.Control type="text" value={data[field.var]} onChange={(e) => handleUpdate(e, field.var)} disabled={true}/>
                        </Form.Group>
                    )

                case 'text-large':
                    if (field.var === 'explanation') {
                        return (
                            <Form.Group key={field.var} controlId={field.var}>
                                <Form.Label>{field.name}</Form.Label>
                                <Form.Control type="text" value={data[field.var]} onChange={(e) => handleUpdate(e, field.var)}/>
                            </Form.Group>
                        )
                    } else {
                        return (
                            <Form.Group key={field.var} controlId={field.var}>
                                <Form.Label>{field.name}</Form.Label>
                                <Form.Control type="text" value={data[field.var]} onChange={(e) => handleUpdate(e, field.var)} disabled={true}/>
                            </Form.Group>
                        )
                    }

                case 'boolean':
                    return (
                        <Form.Group key={field.var} controlId={field.var}>
                            <Form.Check type="checkbox" value={data[field.var]} label={field.var} onChange={(e) => handleUpdate(e, field.var)} disabled={true}/>
                        </Form.Group>
                    )

                default:
                    return null
            }
        }
    }

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {template && template.map((field) => renderFields(field))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Edit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AnomalyEditModal
