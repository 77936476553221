import axios from "axios";
import config from '../config';

let refresh = false;

axios.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 401 && !refresh) {
        refresh = true;

        try {
            const response = await axios.post(`${config.apiUrl}/token/refresh/`, {
                refresh: localStorage.getItem('refresh_token')
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
                // withCredentials: true
            });

            if (response.status === 200) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
                localStorage.setItem('token', response.data.access);
                localStorage.setItem('refresh_token', response.data.refresh);

                // Retry the original request
                error.config.headers['Authorization'] = `Bearer ${response.data.access}`;
                return axios(error.config);
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                window.location.href = '/signin';
            }
        } catch (refreshError) {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            window.location.href = '/signin';
            return Promise.reject(refreshError);
        } finally {
            refresh = false;
        }
    } else {
        refresh = false;
    }

    return Promise.reject(error);
});

export default axios;
