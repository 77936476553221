import axios from 'axios';
import config from '../config';

async function getRawTeamInfo() {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${config.apiUrl}/api/team`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.statusText || 'An error occurred');
    }
}

export { getRawTeamInfo };
