import axios from 'axios';
import config from '../config';

async function getAnomalyList(teamId, timeRange, page, pageSize) {
    const token = localStorage.getItem('token');
    try {
        const response = await axios.get(`${config.apiUrl}/api/anomalylist/${teamId}`, {
            params: {
                range: timeRange,
                page: page,
                pageSize: pageSize
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.statusText || 'An error occurred');
    }
}

export { getAnomalyList };
