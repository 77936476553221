import axios from "axios";
import config from "../config";

async function resendEmail(data) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${config.apiUrl}/api/bulkforgetPasswordRequest`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response?.statusText || "An error occurred");
  }
}

export { resendEmail };
