import React from 'react'
import './style.css'
import { MDBFooter } from 'mdb-react-ui-kit';


function AppFooter() {
    return (
        <MDBFooter bgColor='light' className='footer'>
            <span className="copyright">Copyright &copy; 2024 Ensaras, Inc.</span>
            <a className="terms" href='/terms'>TERMS AND CONDITIONS</a>
        </MDBFooter>
    )
}

export default AppFooter
