import React, { useState } from "react";
import { Modal, Table, Button } from "react-bootstrap";

import { deleteData } from "../../hooks/dataDelete.js";
import "./style.css";

const DataDeleteModal = ({
  show,
  onHide,
  dataInfo,
  formId,
  formName,
  getFormInfo,
  updateMessage,
}) => {
  const handleSubmit = () => {
    deleteData(formId, dataInfo.id)
      .then((response) => {
        // Close the modal
        onHide();
        getFormInfo();
      })
      .catch((error) => {
        updateMessage("error", `[${error.message}] Failed to delete data!`);
      });
  };

  const EquipmentMasterFields = [
    "Electrical Issue",
    "Mechanical Issue",
    "E&I Issue",
  ];

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Data for {formName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <tbody>
            {dataInfo &&
              Object.entries(dataInfo).map(([key, value], index) => (
                <tr key={index}>
                  <td>{key}</td>
                  <td>
                    {formName === "Equipment Downtime" &&
                    EquipmentMasterFields.includes(key)
                      ? value?.join(", ")
                      : value}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DataDeleteModal;
