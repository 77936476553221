import config from '../config'

async function signIn(data) {
    const response = await fetch(`${config.apiUrl}/api/token`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message ||
          error.response?.statusText ||
          "Failed to sign in!");
    }
    return response.json()
}

export { signIn }
