import React, { useState, useEffect } from "react";

import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/home";
import { SignInPage } from "./pages/signin";
import { FormPage } from "./pages/form";
import { AnomalyListPage } from "./pages/anomalyList";
import { Terms } from "./pages/terms";
import ForgotPassword from "./components/passwordReset";
import ResetPasswordRequest from "./components/resetPasswordRequest";
import VerificationModal from "./components/emailVerify";
import SetPassword from "./components/passwordSet";

function AppRoutes() {
  const [token, setToken] = useState("");

  useEffect(() => {
    const previousToken = localStorage.getItem("token");
    if (previousToken) {
      setToken(previousToken);
    }
  }, []);

  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route
        exact
        path="/signin"
        element={<SignInPage token={token} setToken={setToken} />}
      />
      <Route path="/form/:id" element={<FormPage token={token} />} />
      <Route
        path="/anomalylist/:id"
        element={<AnomalyListPage token={token} />}
      />
      <Route exact path="/terms" element={<Terms />} />
      <Route exact path="/reset-password/:id" element={<ForgotPassword />} />
      <Route exact path="/set-password/:id" element={<SetPassword />} />
      <Route exact path="/reset-password" element={<ResetPasswordRequest />} />
      <Route exact path="/verify/:id" element={<VerificationModal show={true} />} />
    </Routes>
  );
}

export default AppRoutes;
